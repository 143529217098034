import Typed from 'typed.js';
require('particles.js');

const PARTICLES_SRC = 'build/data/particles.json';
const PARTICLES_ELEMENT = '#typed-strings';

const run = () => {
    // @ts-ignore
    particlesJS.load('particles', PARTICLES_SRC);
    const typedOptions = {
        stringsElement: PARTICLES_ELEMENT,
        loop: true,
        typeSpeed: 90,
        backSpeed: 40,
        startDelay: 500,
        backDelay: 1200,
        showCursor: false
    };

    new Typed('#typed', typedOptions);
};

// In case the document is already rendered
if (document.readyState != 'loading') {
    run();
    // Modern browsers
} else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', run);
    // IE <= 8
} else {
    // @ts-ignore
    document.attachEvent('onreadystatechange', () => {
        if (document.readyState == 'complete') run();
    });
}
